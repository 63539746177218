<template>
  <div>
    <vs-card v-if="orders.length == 0">
      <div slot="header">
        <p>Não há projetos aguardando liberação.</p>
      </div>
    </vs-card>

    <vs-card :key="index" v-for="(order, index) in orders">
      <div slot="header">
        <h3 :style="{ color: order.classification.color }">
          Requisição {{ order.code }} - [{{ order.classification.name }}]
        </h3>
      </div>

      <div class="card">
        <h4 v-if="order.inova">Desenvolvimento</h4>
        <p>
          Solicitado pelo usuário
          <strong>{{ order.createdUser.name }}</strong>
        </p>
        <p>Código: {{ order.project.code }}</p>
        <p>{{ order.project.name }}</p>
        <vs-divider></vs-divider>
      </div>
      <div slot="footer">
        <vs-button @click="release(order)" class="bt">Liberar</vs-button>
      </div>
    </vs-card>
  </div>
</template>

    <script>
import siriusAPI from "../services";
import { notifyError, notifySuccess } from "@/utils/helpers";
export default {
  data() {
    return {
      orders: [],
    };
  },

  created() {
    this.loadOrders();
  },

  methods: {
    loadOrders() {
      this.$vs.loading();
      new siriusAPI()
        .getOrders(false, false, false, false, "", 9)
        .then((res) => {
          this.orders = res.data;
          this.orders = this.orders.filter(
            (order) => order.project.available == false
          );
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar a lista de Requisições " + error
          );
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    release(order) {
      const data = {
        available: true,
      };
      this.$vs.loading();
      new siriusAPI()
        .releaseProject(data, order.project.id)
        .then(() => {
          notifySuccess(this, "Projeto liberado com sucesso");
          this.$vs.loading.close();
          this.loadOrders();
        })
        .catch((error) => {
          this.$vs.loading.close();
          notifyError(this, "Erro ao tentar liberar o projeto: " + error);
        });
    },
  },
};
</script>
<style scoped>
.select1 {
  padding: 0.8em;
  border: 0.8px solid #ccc;
  border-radius: 5px;
}
.card {
  margin-bottom: 3em;
}
.border {
  border: 1px dotted #6666;
}
.bt {
  margin-right: 1em;
}
.duplicated {
  color: red;
}
</style>
